@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

body {
  font-family: sans-serif;
  font-size: 14px;

  @media only print {
    font-size: 12pt;
  }
}
